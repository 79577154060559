<template>
  <a-modal
    v-model="isVisible"
    class="add-member"
    title="Add Member"
    @cancel="close"
  >
    <a-row>
      <a-col :lg="24" :md="24" :sm="24" :xs="24">
        <a-form :form="form" @submit="addMember">
          <a-form-item>
            <a-row
              type="flex"
              align="middle"
              :gutter="1"
              style="margin-left: 5px"
            >
              <a-col :xs="2">
                <a-icon type="team" />
              </a-col>
              <a-col :xs="22">
                <a-select
                  v-decorator="[
                    'club_id',
                    {
                      rules: [
                        { required: true, message: 'Please select a club.' },
                      ],
                    },
                  ]"
                  placeholder="Select a club"
                  style="width: 100%"
                >
                  <a-select-option
                    v-for="(club, index) in clubList"
                    :key="index"
                    :value="club.id"
                  >
                    {{ club.club_name }}
                  </a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item>
            <a-row
              type="flex"
              align="middle"
              :gutter="1"
              style="margin-left: 5px"
            >
              <a-col :xs="2">
                <a-icon type="mail" />
              </a-col>

              <a-col :xs="22">
                <a-input
                  v-decorator="[
                    'work_email',
                    {
                      rules: [
                        { required: true, message: 'Email is required.' },
                        { type: 'email', message: 'Email is invalid.' },
                      ],
                    },
                  ]"
                  type="email"
                  placeholder="Enter email"
                ></a-input>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item>
            <a-row
              type="flex"
              align="middle"
              :gutter="1"
              style="margin-left: 5px"
            >
              <a-col :xs="2">
                <a-icon type="user-add" />
              </a-col>
              <a-col :xs="22">
                <a-input
                  v-decorator="[
                    'first_name',
                    {
                      rules: [
                        { required: true, message: 'First name is required.' },
                        {
                          min: 3,
                          message:
                            'First name should contain at least 3 Characters.',
                        },
                        {
                          max: 20,
                          message: 'Sorry You are Exceeding the Limit.',
                        },
                      ],
                    },
                  ]"
                  type="text"
                  placeholder="Enter first name"
                ></a-input>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item>
            <a-row
              type="flex"
              align="middle"
              :gutter="1"
              style="margin-left: 5px"
            >
              <a-col :xs="2">
                <a-icon type="user-add" />
              </a-col>
              <a-col :xs="22">
                <a-input
                  v-decorator="[
                    'last_name',
                    {
                      rules: [
                        { required: true, message: 'Last name is required.' },
                        {
                          min: 3,
                          message:
                            'Last name should contain at least 3 Characters.',
                        },
                        {
                          max: 20,
                          message: 'Sorry You are Exceeding the Limit.',
                        },
                      ],
                    },
                  ]"
                  type="text"
                  placeholder="Enter last name"
                ></a-input>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item>
            <a-row
              type="flex"
              align="middle"
              :gutter="1"
              style="margin-left: 5px"
            >
              <a-col :xs="2">
                <a-icon type="mobile" />
              </a-col>
              <a-col :xs="22">
                <div class="custom-phone">
                  <vue-phone-number-input
                    v-model="phone"
                    color="#d9d9d9"
                    valid-color="#d9d9d9"
                    error-color="#d9d9d9"
                    :default-country-code="phoneIso"
                    :preferred-countries="preferredCountries"
                    :all-letters-characters="true"
                    @update="onCountrySelect"
                  ></vue-phone-number-input>
                </div>
                <div v-if="!validPhone" id="number_fld" class="has-error">
                  <div class="ant-form-explain text-left">
                    {{ invalidPhoneMsg }}
                  </div>
                </div>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item>
            <a-row
              type="flex"
              align="middle"
              :gutter="1"
              style="margin-left: 5px"
            >
              <a-col :xs="2">
                <a-icon type="environment" />
              </a-col>
              <a-col :xs="22">
                <a-input
                  v-decorator="[
                    'address_1',
                    {
                      rules: [
                        { required: true, message: 'Address is required.' },
                      ],
                    },
                  ]"
                  type="text"
                  placeholder="Enter address"
                ></a-input>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item>
            <a-row
              type="flex"
              align="middle"
              :gutter="1"
              style="margin-left: 5px"
            >
              <a-col :xs="2">
                <a-icon type="user" />
              </a-col>
              <a-col :xs="22">
                <div class="custom-phone">
                  <vue-phone-number-input
                    v-model="emergency_phone"
                    color="#d9d9d9"
                    valid-color="#d9d9d9"
                    error-color="#d9d9d9"
                    :default-country-code="emergencyPhoneIso"
                    :preferred-countries="preferredCountries"
                    :all-letters-characters="true"
                    @update="onEmergencyPhoneCountrySelect"
                  ></vue-phone-number-input>
                </div>
                <div
                  v-if="!validEmergencyPhone"
                  id="sndnumber_fld"
                  class="has-error"
                >
                  <div class="ant-form-explain text-left">
                    Invalid emergency phone no.
                  </div>
                </div>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item>
            <a-row
              type="flex"
              align="middle"
              :gutter="1"
              style="margin-left: 5px"
            >
              <a-col :xs="2">
                <a-icon type="calendar" />
              </a-col>
              <a-col :xs="22">
                <a-date-picker
                  v-decorator="['dob']"
                  :disabled-date="disabledDate"
                  style="width: 100%"
                  format="DD/MM/YYYY"
                  placeholder="Date of birth"
                />
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item>
            <a-row
              type="block"
              :gutter="1"
              class="gx-text-right"
              style="margin-top: 0px"
            >
              <a-button
                inline-block
                type="primary"
                html-type="submit"
                :loading="memberLoading"
                >Add Member</a-button
              >
            </a-row>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
    <div slot="footer"></div>
  </a-modal>
</template>

<script>
import notifications from "@/common/notifications/notification.service";
import { memberService } from "@/common/api/api.service";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "AddMemberModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keyword: "",
      members: [],
      form: this.$form.createForm(this),
      memberLoading: false,
      country_code: null,
      emergency_phone: null,
      phone: null,
      phoneIso: "IE",
      emergencyPhoneIso: "IE",
      phoneCountryCode: "353",
      emergencyPhoneCountryCode: "353",
      defaultCountry: "IE",
      preferredCountries: ["IE", "US", "GB"],
      validPhone: true,
      invalidPhoneMsg: null,
      validEmergencyPhone: true,
      club_id: null,
      clubList: [],
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.visible;
      },
      set() {
        return false;
      },
    },
    ...mapGetters(["AUTH_USER"]),
  },
  mounted() {
    this.fetchClubList();
  },
  methods: {
    fetchClubList() {
      const params = {
        role: this.AUTH_USER.select_role,
      };
      memberService.ClubList(params).then((resp) => {
        this.clubList = resp.data.result;
      });
    },
    disabledDate(current) {
      return current && current >= moment().subtract(10, "years").endOf("day");
    },
    onCountrySelect(value) {
      if (value != undefined) {
        this.phoneIso = value.countryCode;
        this.phoneCountryCode = value.countryCallingCode;
        this.validPhone = value.isValid;
        this.invalidPhoneMsg = "Invalid phone no.";
      }
    },
    onEmergencyPhoneCountrySelect(value) {
      if (value != undefined) {
        this.emergencyPhoneIso = value.countryCode;
        this.emergencyPhoneCountryCode = value.countryCallingCode;
        this.validEmergencyPhone = value.isValid;
      }
    },
    inviteMember(memberId) {
      memberService.inviteMember(memberId).then((resp) => {
        if (resp.data.success) {
          this.searchEmails();
          notifications.success("An invite has been sent");
        } else {
          notifications.warn("We could not add this member");
        }
      });
    },
    addMember(e) {
      e.preventDefault();
      if (!this.phone) {
        this.validPhone = false;
        this.invalidPhoneMsg = "Phone no. is required.";
      }
      this.form.validateFields((err, values) => {
        if (!err && this.validPhone && this.validEmergencyPhone) {
          if (this.phone === this.emergency_phone) {
            notifications.warn(
              "Phone number and emergency phone number cannot be the same."
            );
            return;
          }
          this.memberLoading = true;
          values.role = this.AUTH_USER.select_role;
          values.phone_no = this.phone;
          values.emergency_phone = this.emergency_phone;
          values.iso2 = this.phoneIso;
          values.country_code = this.phoneCountryCode;
          values.emergency_phone_iso2 = this.emergencyPhoneIso;
          values.emergency_phone_country_code = this.emergencyPhoneCountryCode;

          values.url = window.location.origin + "/#/login";
          memberService
            .addMember2(values)
            .then((resp) => {
              this.memberLoading = false;
              if (resp.data.success) {
                notifications.success("An invite has been sent");
                this.form.resetFields();
                this.emergency_phone = null;
                this.$emit("close");
              } else {
                notifications.warn(resp.data.message);
                this.form.resetFields();
                this.$emit("close");
              }
            })
            .catch((error) => {
              this.memberLoading = false;
            });
        }
      });
    },
    searchEmails() {
      memberService
        .searchMembers({
          keyword: this.keyword,
        })
        .then((resp) => {
          if (resp.data.success) {
            this.members = resp.data.result;
          }
        });
    },
    close() {
      this.form.resetFields();
      this.$emit("close");
    },
  },
};
</script>
<style>
.add-member .ant-modal-footer {
  display: none;
}
.ant-form-item-control-wrapper {
  width: 97%;
}
.has-error .ant-form-explain {
  margin-left: 43px;
}

.ant-form-item-control {
  line-height: 1.3;
}

#number_fld.has-error .ant-form-explain {
  margin-left: 0 !important;
}
#sndnumber_fld.has-error .ant-form-explain {
  margin-left: 0 !important;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .add-member .ant-modal-content {
    height: 75vh;
    overflow: auto;
  }
}
</style>
